var generic = generic || {};
var site = site || {};
var Drupal = Drupal || {};
var settings = Drupal.settings || {};
var FB = FB || '';

(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  var locale = settings.fb_sdk_locale || 'en_US';
  var sdkLocaleList = settings.fb_sdk_locale_list || 0;
  if (sdkLocaleList) {
    var cookieLocale = generic.cookie('LOCALE');
    if (Object.prototype.hasOwnProperty.call(sdkLocaleList, cookieLocale) && sdkLocaleList[cookieLocale]) {
      locale = sdkLocaleList[cookieLocale];
    }
  }
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s); js.id = id;
  js.src = '//connect.facebook.net/' + locale + '/sdk.js';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk')
);

(function($) {
  site.facebook = (function() {
    var nodes = {};

    var _getDOMNodes = function() {
      nodes.container = $('.js-social-login__container');
      nodes.socialContainer = $('.social-login__container');
      nodes.form = $('#facebook_signin');
      nodes.appIdInput = nodes.form.find('input[name="appId"]');
      nodes.tokenInput = nodes.form.find('input[name="token"]');
      nodes.returnUrlInput = nodes.form.find('input[name="RETURN_URL"]');
      nodes.newAccountContainer = $('.js-new-account');
      nodes.errorContainer = nodes.newAccountContainer.find('.error_messages');
      nodes.divider = nodes.container.find('.social-login__divider');
      nodes.optInInput = $('.js-facebook_email_opt_in');
      nodes.fbDisconnect = $('#facebook_disconnect');
    };

    var _initFB = function(appId) {
      FB.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: 'v2.10'
      });
      if (!settings.fb_logpage_excluded) {
        FB.AppEvents.logPageView();
      }

      FB.Event.subscribe('xfbml.render', _showFbContainer);
    };

    if (typeof FB === undefined) {
      FB = Object('FB');
    }

    var _showFbContainer = function() {
      nodes.container.removeClass('hidden');
      nodes.socialContainer.removeClass('hidden');
    };

    var _getAppId = function() {
      if (settings.fb_login_app_id) {
        return settings.fb_login_app_id;
      }
    };

    $('body').on('click', '.js-facebook_disclaimer', function() {
      if ($(this).is(':checked')) {
        $('.fb-overlay, .fb-disclaimer-error').hide();
      } else {
        $('.fb-overlay, .fb-disclaimer-error').show();
      }
    });

    $('body').on('click', '.fb-overlay', function() {
      $('.fb-disclaimer-error').removeClass('hidden');
    });
    /**
    * Given a response from FB.getLoginStatus (called on page load),
    * user out). Since we can't reliably detect if the user's logged into the
    * site, we just handle logging out. If we could reliably detect that the
    * user was logged into the site, we'd check for (response.status ==
    * 'connected' and <user not logged in>), and log them in.
    *
    * @method _handleLoginStatusResponse
    * @param {Object} response - Reeponse from the call to FB.logout
    * @returns {Void} No return for you!
    */
    var _handleLoginStatusResponse = function(response) {
      //console.log("Facebook status in _handleLoginStatusResponse: " + response.status);
      //If we're on the signout page (for any reason - timeout or logout),
      //log the user out according to Facebook
      if (location.pathname.match('/account/signin.tmpl') && location.search.match('_SUBMIT=signout') && (response.status === 'connected')) {
        // FB.logout likes to not work. So, we call this recursively until it
        // does.
        FB.logout(_handleLogoutSessionResponse);
      }
    };

    /**
    * Given a response from FB.logout, makes sure the user's Facebook account
    * is no longer "connected" (logged in) to our site. If not, it keeps trying
    * FB.logout until response.status isn't "connected".
    *
    * @method _handleLogoutSessionResponse
    * @param {Object} response - Reeponse from the call to FB.logout
    * @returns {Void} No return for you!
    */
    var _handleLogoutSessionResponse = function(response) {
      //console.log("Facebook status in _handleLogoutSessionResponse: " + response.status);
      // If we're connected, disconnect, again recursively because Facebook is
      // clingy.
      if (response.status === 'connected') {
        FB.logout(_handleLogoutSessionResponse);
      }
    };

    /**
    * Initializes the Facebook SDK and displays the login button.
    *
    * Note that this function can be called more than once - if you're editing
    * it, be aware of that. For example, it's called on page load, but also
    * if the Facebook button is in a panel that's reloaded via an AJAX call
    * (see Perlgem's sites/bobbibrown/na_ms/js/pc/site/checkout/signin.js).
    *
    * As part of the initialization:
    *- The Facebook SDK retrieves the user's token, if her account's already connected
    * - The URL is checked. If the user's on a logout page, she's logged out of Facebook.
    * - Any FB login-related buttons are connected to event handlers
    *
    * This method isn't really private - see "return" below which exposes
    * it as "site.facebook.init" to the outside world.
    *
    * @method _init
    * @returns {Void}
    */

    var _init = function() {
      _getDOMNodes();

      // Make sure that the form action points to the SSL version otherwise it fails to log in
      var actionUrl = '/account/signin.tmpl';
      var returnUrl = null;
      if (location.pathname.match('/checkout/index.tmpl')) {
        actionUrl = '/checkout/index.tmpl';
        returnUrl = actionUrl;
        nodes.returnUrlInput.val(returnUrl);
      } else if (location.pathname.match('/checkout/checkout.tmpl') || location.pathname.match('/checkout/signin.tmpl')) {
        // Non-single page checkout setting
        actionUrl = '/checkout/checkout.tmpl';
      } else if (location.pathname.match('/checkout/shipping.tmpl')) {
        // Non-single page checkout setting - Enhanced delivery sites
        actionUrl = '/checkout/shipping.tmpl';
      }
      if (location.search.match('bvdisplaycode')) {
        actionUrl = location.pathname + location.search;
      }
      nodes.form.attr('action', 'https://' + window.location.hostname + actionUrl);

      // Set the RETURN_URL to the currently viewed page
      // BB NA redirects to account/checkout index page
      // ACAC-2004 redirect to profile preferences page when clicked on order confirmation
      if (location.pathname.match('/checkout/confirm.tmpl')) {
        //var returnUrl = [location.protocol, '//', location.host, location.pathname].join('');
        returnUrl = '/account/signin.tmpl';
        nodes.returnUrlInput.val(returnUrl);
      }
      if (location.pathname.match('/checkout/checkout.tmpl')) {
        actionUrl = '/checkout/checkout.tmpl';
        nodes.returnUrlInput.val(returnUrl);
      }
      if (location.search.match('RETURN_URL')) {
        if (typeof URLSearchParams === 'function') {
          var query_string = new URLSearchParams(location.search);
          returnUrl = query_string.get('RETURN_URL');
        } else {
          returnUrl = getUrlParameter('RETURN_URL');
        }
        if (typeof returnUrl !== 'undefined') {
          nodes.returnUrlInput.val(returnUrl);
        }
      }
      if (location.search.match('bvdisplaycode')) {
        returnUrl = location.pathname + location.search;
        nodes.returnUrlInput.val(returnUrl);
      }
      // Hook up any additional "opt in" input field on the page to the main
      // // facebook_signin form's fields.
      if (nodes.optInInput !== 'undefined') {
        nodes.optInInput.on('click', function() {
          // Find the field used to opt the user into email promotions
          var $optInFormInput = $('#form--facebook_signin--field--PC_EMAIL_PROMOTIONS');

          // match the checkbox state
          $optInFormInput.prop('checked', $(this).is(':checked'));

          // and fill in the LAST_SOURCE field
          var source = 'Facebook_gnav';
          // (Note that /checkout/confirm.tmpl must come before /checkout/)
          if (location.pathname.match('/checkout/confirm.tmpl')) {
            source = 'Facebook_order_confirmation';
          } else if (location.pathname.match('/account/signin.tmpl') || location.pathname.match('/account/index.tmpl')) {
            source = 'Facebook_account_signup';
          } else if (location.pathname.match('/checkout/')) {
            source = 'Facebook_checkout_signin';
          }
          $('#form--facebook_signin--field--LAST_SOURCE').val(source);
        });
      }

      // When the user clicks "Disconnect" in her account, "disconnect"
      // her Facebook account (which, in Facebook terms, means "logout"),
      // *then* submit the facebook_disconnect form so that the back end can
      // clear her Facebook data from USER_LOGINS_TBL and USER_SOCIAL_INFO_TBL.
      if (nodes.fbDisconnect !== 'undefined') {
        nodes.fbDisconnect.on('click', function(event) {
          event.preventDefault(); // We'll post the form when we're done
          FB.logout(function(response) {
            // Make sure FB logged us out
            _handleLogoutSessionResponse(response);
            // Tell the back end to clear their account link
            nodes.fbDisconnect.submit();
          });
        });
      }

      $('body').on('click', '.js-facebook-button', function(e) {
        e.preventDefault();
        FB.login(function(response) {
          if (response.status === 'connected') {
            site.facebook.continueWithFacebook();
          }
        });
      });

      var appId = _getAppId();

      if (appId) {
        if (typeof FB === 'object') {
          _initFB(appId);
        }
      }
      // Fetch what Facebook thinks the the user's current login status is.
      // We *should* at this point log the user in if Facebook says they're
      // logged in, but we can't reliably tell if the user's logged in on our
      // end, and we don't want to randomly send them to their Account page,
      // so we do nothing instead. But, we need to call this so that
      // Facebook is set up in case we call FB.logout.
      if (typeof FB === 'object') {
        FB.getLoginStatus(function(response) {
          _handleLoginStatusResponse(response);
        });
      }
    };
    /**
    * Determines the current login state of the user and logs her in if needed.
    *
    * This handler is called after the "Continue with Facebook" button
    * is clicked. This should mean that the user's "connected" (logged in)
    * state has changed, so we need to react accordingly.
    *
    * If the users "status" is connected, it means they're logged in, so
    * we post the login form to log them into the site.
    *
    * If the status is "not_authorized", it means they told the Facebook popup
    * not to allow our site access to their Facebook account. So, we display
    * an error (since they clicked the Continue with Facebook button but said
    * we can't access their account, we can't log them in).
    *
    * If the status is "unknown", it usually means they clicked "Cancel".
    * In any case, it means they're not "connected" (logged in) as far as
    * Facebook is concerned, so we don't log them in.
    *
    * If response.status is anything else, it shouldn't be, so we display an
    * error.
    *
    * @method _handleContinueWithFacebook
    * @param {Object} response - A response from the Facebook SDK after
    *     Continue with Facebook has been clicked.
    * @returns {Void} Nothing but emptiness.
    */
    var _handleContinueWithFacebook = function(response) {
      if (response.status === 'connected') {
        // Extract authResponse.accessToken and submit the login form.
        // Note that this is probably redundant - they should already be logged
        //in.
        var token = response.authResponse.accessToken;
        nodes.tokenInput.val(token);
        nodes.form.submit();
      } else if (response.status === 'not_authorized') {
        // display error that app hasnt been authorized
        generic.showErrors([{text: site.facebook.not_authorized}], nodes.errorContainer);
      } else if (response.status === 'unknown') {
        // Do nothing - login didn't happen (usually means they clicked Cancel)
        // If we were tightly coupled with the site's login state, we'd log
        // the user out. But we interpret this more as "disconnected from
        // Facebook", so we do nothing.
        //console.log("_handleContinueWithFacebook called with response.status === 'unknown'");
      } else {
        generic.showErrors([{text: site.facebook.login_error}], nodes.errorContainer);
      }
    };

    /* Fallback function if URLSearchParams is not available
    * Returning get parameter value from the current URL
    *
    * @param {String} name - Name of the parameter whose value you want to
    *    retrieve.
    * @returns {String} - parameter value from the current URL for the
    *    parameter identified by "name", or "" if the parameter doesn't exist.
    */
    var getUrlParameter = function(name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    return {
      init: function() {
        _init();
      },
      continueWithFacebook: function() {
        var facebookDisclaimer = $('input.js-facebook_disclaimer');
        if (facebookDisclaimer.hasClass('js-facebook_disclaimer') && !facebookDisclaimer.is(':checked')) {
          $('.fb-disclaimer-error').removeClass('hidden');
          return false;
        }
        FB.getLoginStatus(function(response) {
          _handleContinueWithFacebook(response);
        });
      }
    };
  }());

  window.fbAsyncInit = function() {
    site.facebook.init();
  };
})(jQuery);
